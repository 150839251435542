export const settings = {
	homeOrder: [
		{ title: 'Favorited', type: 'starred' },
		{ title: 'Recently Added', type: 'newest' },
		{ title: 'Most Played', type: 'frequent' },
		{ title: 'Recently Played', type: 'recent' },
	],
	// Future settings
	endOfQueue: 'repeat', // stop, repeat, random, recomandation
	cacheNextSong: 5,
}